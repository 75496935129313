import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddMemberModal from "./AddMember";

export default function AddMemberPage() {
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowModal(false);
    navigate("/hrms/user-management");
  };

  return (
    <>
      {showModal && (
        <AddMemberModal
          show={showModal}
          handleClose={handleClose}
          employees={[]}
        />
      )}
    </>
  );
}
