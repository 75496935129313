import React, { useState, useEffect } from "react";
// import Topbar from "../../../../Dashboard.js";
// import Leftdashboard from "../../../../Leftdashboard.js";
import axios from "axios";
import "./ListMembers.css";
import AddMemberModal from "./AddMember";

export default function ListMembers() {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [editModal, setEditModal] = useState(false); // Modal state
  const [editMember, setEditMember] = useState({
    member_id: "",
    member_name: "",
    member_email: "",
    joining_date: "",
    status: "",
  });
  const handleOpenModal = () => setShowAddMemberModal(true);
  const handleCloseModal = () => setShowAddMemberModal(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://uatapi.policicue.com/hrms/listMembers?page=1&limit=10"
        );

        // Check if the response contains valid data
        if (
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          setData(response.data.data);
          setFilteredData(response.data.data); // Initially display all data
        } else {
          setData([]);
          setFilteredData([]);
          setError(response.data.message || "No data found."); // Use the message from the API if available
        }
      } catch (err) {
        console.error(err);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = data.filter((member) => {
      const { member_name, member_email, joining_date } = member.memberData;
      return (
        member_name.toLowerCase().includes(query) ||
        member_email.toLowerCase().includes(query) ||
        joining_date.toLowerCase().includes(query)
      );
    });
    setFilteredData(filtered);
  };

  const renderPermissions = (permissions) => {
    if (!permissions || permissions.length === 0) {
      return "No Permissions";
    }
    return permissions
      .map((perm) => `Permission ${perm.permission_id}`)
      .join(", ");
  };

  const handleDelete = async (memberId) => {
    try {
      const response = await axios.post(
        "https://uatapi.policicue.com/hrms/deleteMember",
        {
          member_id: memberId,
        }
      );

      // If the deletion is successful, remove the member from the data
      if (response.data.success) {
        setData(
          data.filter((member) => member.memberData.member_id !== memberId)
        );
        setFilteredData(
          filteredData.filter(
            (member) => member.memberData.member_id !== memberId
          )
        );
      }
    } catch (err) {
      console.error(err);
      setError("Failed to delete member.");
    }
  };
  const handleEdit = (member) => {
    setEditMember({
      member_id: member.memberData.member_id,
      member_name: member.memberData.member_name,
      member_email: member.memberData.member_email,
      joining_date: member.memberData.joining_date,
      status: member.memberData.status,
    });
    setEditModal(true); // Open edit modal
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditMember((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.post(
        "https://uatapi.policicue.com/hrms/updateMember",
        editMember
      );
      setEditModal(false);

      if (response.data.success) {
        // Re-fetch the list after a successful update
        const updatedResponse = await axios.post(
          "https://uatapi.policicue.com/hrms/listMembers?page=1&limit=10"
        );
        setData(updatedResponse.data.data);
        setFilteredData(updatedResponse.data.data);

        // Close edit modal
      }
    } catch (err) {
      console.error(err);
      setError("Failed to update member.");
    }
  };

  return (
    <div className="ListMembers">
      {/* <Topbar /> */}
      <div className="container-fluid my-2">
        <div className="row">
          {/* <Leftdashboard /> */}

          <main className="mainBodyContainer">
            <div
              className="bg-white shadow p-4 rounded-3"
              style={{ marginBottom: "1.8rem" }}
            >
              <div className="d-flex justify-content-between pb-4  py-0 ">
                <div className="bg-white">
                  <h4
                    className="text-capitalize icueTextPrimary"
                    style={{ fontFamily: "ErasDemiITC" }}
                  >
                    Add Member
                  </h4>
                </div>
                <div className="bg-white">
                  <button
                    type="button"
                    className="btn icueBgPrimary text-white"
                    style={{
                      marginLeft: "15px",
                      width: "250px",
                      textAlign: "center",
                      fontSize: "15px",
                      borderRadius: "4px",
                      paddingBlock: "0.5rem",
                      transition: "none",
                    }}
                    onClick={handleOpenModal}
                  >
                    <i className="bi bi-plus-circle"></i>&nbsp; Add Member
                  </button>
                </div>
              </div>
            </div>

            {/* <div class="bg-transparent p-2  rounded-3">
                <nav
                  style={{
                    bsbreadcrumbdivider:
                      'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")',
                  }}
                  aria-label="breadcrumb"
                >
                  <ol class="breadcrumb mb-0 justify-content-end">
                    <li
                      class="breadcrumb-item bg-transparent"
                      style={{ fontFamily: "Calibri", fontSize: "bold" }}
                    >
                      <a
                        href="/Superdashboard"
                        class="text-decoration-none text-info"
                      >
                        Dashboard
                      </a>
                    </li>
                    <li
                      class="breadcrumb-item bg-transparent"
                      style={{ fontFamily: "Calibri" }}
                    >
                      <a href="/lms" class="text-decoration-none text-info">
                        User Mangement
                      </a>
                    </li>
                    <li
                      class="breadcrumb-item text-muted text-capitalize bg-transparent"
                      aria-current="page"
                      style={{ fontSize: "bold" }}
                    >
                      List of Members
                    </li>
                  </ol>
                </nav>
              </div> */}

            {/* Search Bar */}

            <div className="bg-white p-2 rounded-3 shadow-sm">
              <div class="d-flex justify-content-between">
                <h4
                  class="text-captalize"
                  style={{ color: "#3a3285", padding: "5px" }}
                >
                  List of Members
                </h4>
              </div>
              <div class="icueSearchBox w-100 ">
                <div
                  className="input-group mb-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Users by Name, Email or Date"
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{ backgroundColor: "#F4F7FC" }}
                  />

                  <div>
                    {/* Add Member Button */}

                    {/* Add Member Modal */}
                    {showAddMemberModal && (
                      <AddMemberModal
                        show={showAddMemberModal}
                        handleClose={handleCloseModal}
                        employees={[]} // Pass any necessary props
                      />
                    )}
                  </div>
                </div>
              </div>
              {loading ? (
                <p>Loading members...</p>
              ) : error ? (
                <p className="text-danger">{error}</p>
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover results">
                    <thead>
                      <tr
                        className="text-uppercase"
                        style={{
                          fontFamily: "Calibri",
                          fontStyle: "normal",
                          fontSize: "12px",
                          backgroundColor: "#F4F7FC",
                        }}
                      >
                        <th className="py-3">ID</th>
                        <th className="py-3">NAME</th>
                        <th className="py-3 d-none d-md-table-cell">EMAIL</th>
                        <th className="py-3">ROLE</th>
                        <th className="py-3 d-none d-lg-table-cell">
                          DEPARTMENT
                        </th>
                        <th className="py-3">JOINED ON</th>
                        <th className="py-3 d-none d-md-table-cell">
                          PERMISSIONS
                        </th>
                        <th className="py-3">STATUS</th>
                        <th className="py-3">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontFamily: "Calibri", fontSize: "14px" }}>
                      {filteredData.map((member, index) => (
                        <tr
                          key={member.memberData.member_id}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#FFFFFF" : "#F4F7FC",
                          }}
                        >
                          <td>{member.memberData.member_id}</td>
                          <td>{member.memberData.member_name}</td>
                          <td className="d-none d-md-table-cell">
                            {member.memberData.member_email}
                          </td>
                          <td>{member.memberData.role_name}</td>
                          <td className="d-none d-lg-table-cell">
                            {member.memberData.department_name}
                          </td>
                          <td>{member.memberData.joining_date}</td>
                          <td className="d-none d-md-table-cell">
                            {renderPermissions(member.memberPermission)}
                          </td>
                          <td>
                            <span
                              className={`badge rounded-pill ${
                                member.memberData.status === "Active"
                                  ? "bg-success"
                                  : "bg-secondary"
                              } py-2 w-75`}
                            >
                              {member.memberData.status}
                            </span>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-transparent text-muted"
                              onClick={() => handleEdit(member)} // Edit button handler
                            >
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-transparent"
                              onClick={() =>
                                handleDelete(member.memberData.member_id)
                              } // Delete button handler
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-transparent"
                            >
                              <i className="bi bi-three-dots"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      {editModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Member</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setEditModal(false)} // Close modal
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="mb-3">
                    <label htmlFor="member_name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="member_name"
                      name="member_name"
                      value={editMember.member_name}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="member_email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="member_email"
                      name="member_email"
                      value={editMember.member_email}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="joining_date" className="form-label">
                      Joining Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="joining_date"
                      name="joining_date"
                      value={editMember.joining_date}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-control"
                      id="status"
                      name="status"
                      value={editMember.status}
                      onChange={handleEditChange}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setEditModal(false)} // Close modal
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSaveEdit} // Save changes
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
