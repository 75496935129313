import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "./Department.css";
import { nanoid } from "nanoid";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
// import Topbar from "../../../Dashboard";
// import Leftdashboard from "../../../Leftdashboard";

const AddDepartments = () => {
  const [departments, setDepartments] = useState([]);
  const [addFormData, setAddFormData] = useState({ department_name: "" });
  const [editFormData, setEditFormData] = useState({ department_name: "" });

  const [editDepartmentId, setEditDepartmentId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  // Function to fetch departments with pagination
  const fetchDepartmentsList = async (currentPage) => {
    try {
      const response = await axios.post(
        `https://uatapi.policicue.com/hrms/listDepartment?page=${currentPage}&limit=${itemsPerPage}`
      );
      const data = response.data;
      if (data && data.data) {
        setDepartments(data.data);
        const totalItems = data.total || itemsPerPage * data.totalPages;
        setTotalPages(totalItems ? Math.ceil(totalItems / itemsPerPage) : 1);
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartmentsList(currentPage);
  }, [currentPage]);

  const handleAddFormChange = (event) => {
    const { name, value } = event.target;
    setAddFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const newDepartment = { department_name: addFormData.department_name };
      await axios.post(
        "https://uatapi.policicue.com/hrms/addDepartment",
        newDepartment
      );
      setAddFormData({ department_name: "" });
      fetchDepartmentsList(currentPage);
    } catch (error) {
      console.error("Error adding department:", error);
    }
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedDepartment = {
        department_id: editDepartmentId,
        department_name: editFormData.department_name,
      };
      await axios.post(
        `https://uatapi.policicue.com/hrms/updateDepartment`,
        updatedDepartment
      );
      setEditDepartmentId(null);
      fetchDepartmentsList(currentPage);
    } catch (error) {
      console.error("Error updating department:", error);
    }
  };

  const handleEditClick = (event, department) => {
    event.preventDefault();
    setEditDepartmentId(department.department_id);
    setEditFormData({ department_name: department.department_name });
  };

  const handleCancelClick = () => {
    setEditDepartmentId(null);
  };

  const handleDeleteClick = async (departmentId) => {
    try {
      await axios.post(
        "https://uatapi.policicue.com/hrms/deleteDepartment",
        { department_id: departmentId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      fetchDepartmentsList(currentPage);
    } catch (error) {
      console.error("Error deleting department:", error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  return (
    <div className="department">
      {/* <Topbar /> */}
      <div className="container-fluid my-2">
        <div className="row">
          {/* <Leftdashboard /> */}
          <main className="mainBodyContainer">
            <div className="row my-2">
              <div className="col-12 py-2 px-3">
                <h4 className="section-title">Add Departments</h4>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-12 py-1 px-3">
                <div className="bg-white px-5 rounded-3 shadow-sm">
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-6 col-12 add-department"
                      style={{ backgroundColor: "#F4F7FC" }}
                    >
                      <label className="form-label icueTextPrimary department-label">
                        Enter Department Name
                      </label>
                      <form onSubmit={handleAddFormSubmit}>
                        <input
                          type="text"
                          name="department_name"
                          required
                          className="form-control bg-white text-capitalize department-input"
                          onChange={handleAddFormChange}
                          value={addFormData.department}
                        />
                      </form>
                      <button
                        className="btn-sm text-white add-department-btn"
                        onClick={handleAddFormSubmit}
                      >
                        <i className="bi bi-plus-circle"></i> Add Department
                      </button>
                    </div>

                    <div className="col-lg-8 col-md-6 col-12 bg-white tab-content py-1 px-3">
                      <form onSubmit={handleEditFormSubmit}>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr
                              className="table-header"
                              style={{ backgroundColor: "white" }}
                            >
                              <th className="">Department</th>
                              <th className="text-center  actions-header">
                                Actions
                              </th>
                            </tr>
                          </thead>

                          <tbody className="table-body">
                            {departments.map((department) => (
                              <Fragment key={department.department_id}>
                                {editDepartmentId ===
                                department.department_id ? (
                                  <EditableRow
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <ReadOnlyRow
                                    department={department}
                                    handleEditClick={handleEditClick}
                                    handleDeleteClick={handleDeleteClick}
                                  />
                                )}
                              </Fragment>
                            ))}
                          </tbody>
                        </table>
                      </form>
                      {/* <div className="pagination">
                      <span
                        onClick={() =>
                          handlePageChange(
                            currentPage > 1 ? currentPage - 1 : 1
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        &lt; 
                      </span>
                      <span> Page {currentPage} </span>
                      <span
                        onClick={() => handlePageChange(currentPage + 1)}
                        style={{ cursor: "pointer" }}
                      >
                        &gt; 
                      </span> 
                    </div> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div className="pagination mt-3">
                          <button
                            onClick={handlePreviousPage}
                            style={{
                              fontWeight: "bold",
                              cursor:
                                currentPage > 1 ? "pointer" : "not-allowed",
                            }}
                            disabled={currentPage === 1}
                            className=""
                          >
                            <i className="bi bi-arrow-left"></i>
                          </button>
                          <span>
                            Page {currentPage} of {totalPages}
                          </span>
                          <button
                            onClick={handleNextPage}
                            style={{
                              fontWeight: "bold",
                              cursor:
                                currentPage < totalPages
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                            disabled={currentPage === totalPages}
                            className=""
                          >
                            <i className="bi bi-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AddDepartments;
