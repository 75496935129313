import React from "react";

const ReadOnlyRow = ({
  department,
  editDepartmentId,
  editFormData,
  handleEditFormSubmit,
  handleEditClick,
  handleDeleteClick,
}) => {
  const isEditing = department.department_id === editDepartmentId;

  return (
    <tr>
      {isEditing ? (
        <>
          <td>
            <input
              type="text"
              name="department_name"
              value={editFormData.department_name}
              onChange={(e) => editFormData.onChange(e)}
            />
          </td>
          <td>
            <button type="button" onClick={handleEditFormSubmit}>
              Save
            </button>
          </td>
        </>
      ) : (
        <>
          <td>{department.department_name}</td>
          <td style={{ padding: "0" }}>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-sm btn-transparent"
                type="button"
                onClick={(event) => handleEditClick(event, department)}
              >
                <i className="bi bi-pencil"></i>
              </button>
              <button
                className="btn btn-sm btn-transparent"
                type="button"
                onClick={() => handleDeleteClick(department.department_id)}
              >
                <i className="bi bi-trash"></i>
              </button>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default ReadOnlyRow;
