import { toast } from "react-toastify";
import Constant from "./constant";

const format_date = (date) => {
  const d = new Date(date).toLocaleDateString("en-IN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  var mdy = d.split("/");
  var day = mdy[0];
  var month = mdy[1];
  var year = mdy[2];
  return `${year}-${month}-${day}`;
};

const cattleformat_date = (date) => {
  const d = new Date(date).toLocaleDateString("en-IN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  var mdy = d.split("/");
  var day = mdy[0];
  var month = mdy[1];
  var year = mdy[2];
  return `${day}-${month}-${year}`;
};
const isValidDate = (date) => date instanceof Date && !isNaN(date.valueOf())
const editformat_date = (date) => {
  if(!date) return
  const dateArr = date?.split("-")
  let covertedDate = new Date(`${dateArr[1]}-${dateArr[0]}-${dateArr[2]}`)
  if (!isValidDate(covertedDate)) {
    covertedDate = new Date(date)
    if (!isValidDate(covertedDate)) return date
    
  }
  return covertedDate
};

const file = (key) => {
  // return `${Constant.BASE_URL}pos/file/pos/${key}`;
  return key;
}

const stringFormat = (value) => {
  if (value === null) { return "" };
  return String(value);
}

const stringFormatHandleEmpty = (value) => {
  if (value === null) { return "" };
  return value ? String(value) : '';
}

const claimformat_date = (date) => {
  const d = new Date(date).toLocaleDateString("en-IN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  var mdy = d.split("/");
  var day = mdy[0];
  var month = mdy[1];
  var year = mdy[2];
  return `${year}-${month}-${day}`;
};
const checkImgValidation = (filesize) => {
   const fsize = filesize.size;
    const file = Math.round((fsize / 1024));
    if (file >= 1000) {
      toast.error("File too Big, please select a file less than 1000kb")
      return false;
  } return true;
}

export { format_date, cattleformat_date, editformat_date, file, stringFormat, stringFormatHandleEmpty, claimformat_date, checkImgValidation };