const ReadOnlyRow = ({ contact, handleEditClick, handleDeleteClick }) => {
  return (
    <tr>
      <td>{contact.role_name}</td>
      <td className="actions-column" style={{ padding: 0 }}>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-sm btn-transparent"
            type="button"
            onClick={(event) => handleEditClick(event, contact)}
          >
            <i className="bi bi-pencil"></i>
          </button>
          <button
            className="btn btn-sm btn-transparent"
            type="button"
            onClick={() => handleDeleteClick(contact.role_id)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;
