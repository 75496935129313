import React from "react";

const ReadOnlyRow = ({ contact, handleEditClick, handleDeleteClick }) => {
  return (
    <tr>
      <td>{contact.holiday_name}</td>
      <td>{contact.holiday_date}</td>
      <td>{contact.holiday_day}</td>

      <td style={{ textAlign: "center" }}>
        <div className="d-flex  gap-2">
          <button
            className="btn btn-sm btn-transparent"
            style={{}}
            type="button"
            onClick={(event) => handleEditClick(event, contact)}
          >
            <i className="bi bi-pencil"></i>
          </button>
          <button
            className="btn btn-sm btn-transparent"
            style={{}}
            type="button"
            onClick={() => handleDeleteClick(contact.id)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;
