import React, { useState, useEffect, Fragment } from "react";
import "./Holiday.css";
// import Topbar from "../../../Dashboard.js";

import axios from "axios";
// import Leftdashboard from "../../../Leftdashboard.js";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
// Holidays
const Holidays = () => {
  const [contacts, setContacts] = useState([]);
  const [addFormData, setAddFormData] = useState({
    namee: "",
    date: "",
    day: "",
  });
  const [editFormData, setEditFormData] = useState({
    namee: "",
    date: "",
    day: "",
  });
  const [editContactId, setEditContactId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchHolidays(page);
  }, [page]);

  const fetchHolidays = async (page) => {
    try {
      const response = await axios.post(
        `https://uatapi.policicue.com/hrms/listLeave?page=${page}&limit=${limit}`
      );

      if (response.data.status === 1 && Array.isArray(response.data.data)) {
        setContacts(response.data.data);
        setTotalPages(Math.ceil(response.data.total / limit));
      } else {
        console.error("Unexpected API response format:", response.data);
        setContacts([]);
      }
    } catch (error) {
      console.error("Error fetching holidays:", error);
      setContacts([]);
    }
  };

  const handleAddFormChange = (event) => {
    const { name, value } = event.target;
    setAddFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    const newHoliday = {
      holiday_name: addFormData.namee,
      holiday_date: addFormData.date,
      holiday_day: addFormData.day,
    };
    try {
      await axios.post(
        "https://uatapi.policicue.com/hrms/addLeave",
        newHoliday
      );
      fetchHolidays(page);
      setAddFormData({ namee: "", date: "", day: "" });
    } catch (error) {
      console.error("Error adding holiday:", error);
    }
  };

  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);
    setEditFormData({
      namee: contact.holiday_name,
      date: contact.holiday_date,
      holiday_day: contact.holiday_day,
    });
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    const editedContact = {
      id: editContactId,
      holiday_name: editFormData.namee,
      holiday_date: editFormData.date,
      holiday_day: editFormData.day,
    };
    try {
      await axios.post(
        "https://uatapi.policicue.com/hrms/updateLeave",
        editedContact
      );
      fetchHolidays(page);
      setEditContactId(null);
    } catch (error) {
      console.error("Error editing holiday:", error);
    }
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = async (contactId) => {
    try {
      await axios.post(
        "https://uatapi.policicue.com/hrms/deleteLeave",
        { id: contactId }
      );
      fetchHolidays(page);
    } catch (error) {
      console.error("Error deleting holiday:", error);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div className="Holidays">
      {/* <Topbar /> */}
      <div className="container-fluid my-3">
        <div className="row">
          {/* <Leftdashboard /> */}
          <main className="mainBodyContainer">
            <div className="row mb-3">
              <div className="col-12 py-2 px-3">
                <h4
                  className="text-capitalize"
                  style={{ fontFamily: "ErasDemiITC" }}
                >
                  Holidays 2021
                </h4>
              </div>
            </div>
            <div className="row mb-4">
              <div
                className="col-12 bg-white p-4 rounded-3 shadow-sm"
                style={{}}
              >
                <div className="row">
                  <div
                    className="col-12 col-md-4 mb-3"
                    style={{ backgroundColor: "#F4F7FC" }}
                  >
                    <form onSubmit={handleAddFormSubmit} className="p-3">
                      <label className="form-label">Holiday Name</label>
                      <input
                        type="text"
                        name="namee"
                        required="required"
                        onChange={handleAddFormChange}
                        value={addFormData.namee}
                        className="form-control mb-3"
                      />
                      <label className="form-label">Holiday Date</label>
                      <input
                        type="date"
                        name="date"
                        required="required"
                        onChange={handleAddFormChange}
                        value={addFormData.date}
                        className="form-control mb-3"
                      />
                      <label className="form-label">Holiday Day</label>
                      <input
                        type="text"
                        name="day"
                        required="required"
                        onChange={handleAddFormChange}
                        value={addFormData.day}
                        className="form-control mb-3"
                      />
                      <button
                        type="submit"
                        className="btn w-100"
                        style={{ backgroundColor: "#3a3285", color: "#fff" }}
                      >
                        Add Holiday
                      </button>
                    </form>
                  </div>
                  <div className="col-12 col-md-8">
                    <form onSubmit={handleEditFormSubmit}>
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr
                            className="text-uppercase"
                            style={{
                              lineHeight: "20px",
                              fontFamily: "Calibri",
                              fontSize: "14px",
                              backgroundColor: "white",
                            }}
                          >
                            <th className="py-3">Holiday Name</th>
                            <th className="py-3">Holiday Date</th>
                            <th className="py-3">Day</th>
                            <th className="py-3 ">Action</th>{" "}
                            {/* Center-align Action column */}
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            lineHeight: "20px",
                            fontFamily: "Calibri",
                            fontSize: "16px",
                          }}
                        >
                          {contacts.map((contact) => (
                            <Fragment key={contact.id}>
                              {editContactId === contact.id ? (
                                <EditableRow
                                  editFormData={editFormData}
                                  handleEditFormChange={handleEditFormChange}
                                  handleCancelClick={handleCancelClick}
                                />
                              ) : (
                                <ReadOnlyRow
                                  contact={contact}
                                  handleEditClick={handleEditClick}
                                  handleDeleteClick={handleDeleteClick}
                                />
                              )}
                            </Fragment>
                          ))}
                        </tbody>
                      </table>
                    </form>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="pagination">
                        <span
                          onClick={handlePreviousPage}
                          disabled={page === 1}
                          style={{ cursor: "pointer" }}
                        >
                          &lt;
                        </span>{" "}
                        <span> Page {page} </span>
                        <span
                          onClick={handleNextPage}
                          style={{ cursor: "pointer" }}
                          disabled={page === totalPages}
                        >
                          &gt;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Holidays;
