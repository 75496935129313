import React, { useState, useEffect } from "react";
import axios from "axios";
// import Topbar from "../../../../Dashboard";
// import Leftdashboard from "../../../../Leftdashboard";
import ApprovedLeaves from "./ApprovedLeaves";

// import reqLeaveData from "./leavedata";

export default function RejectedLeaves() {
  //   const [reqdata, setReqdata] = useState();
  const [rejectedLeaves, setrejectedLeaves] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchApprovedLeaves = async () => {
      try {
        const response = await axios.post(
          "https://uatapi.policicue.com/hrms/requestedLeaves?page=1&limit=10"
        );
        const filteredLeaves = response.data.data.filter(
          (leave) => leave.leave_status === "Rejected"
        );
        setrejectedLeaves(filteredLeaves);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch Rejected leaves.");
        setLoading(false);
      }
    };

    fetchApprovedLeaves();
  }, []);
  return (
    <div className="Leaves">
      {/* <!-- Top Bar --> */}

      {/* <Topbar /> */}
      {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
      <div class="container-fluid my-2">
        <div class="row">
          {/* <Leftdashboard /> */}

          <main class="mainBodyContainer">
            <div class="row my-2">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                <div class="d-flex justify-content-between">
                  <h4
                    class="text-captalize"
                    style={{
                      fontFamily: "ErasDemiITC",
                      fontWeight: "bold",
                      marginTop: "1rem",
                      marginLeft: "12px",
                    }}
                  >
                    Rejected Leaves
                  </h4>
                  <div class="bg-transparent p-2  rounded-3">
                    <nav
                      style={{
                        bsbreadcrumbdivider:
                          'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")',
                      }}
                      aria-label="breadcrumb"
                    >
                      <ol class="breadcrumb mb-0 justify-content-end">
                        <li
                          class="breadcrumb-item bg-transparent"
                          style={{ fontFamily: "Calibri", fontSize: "bold" }}
                        >
                          <a
                            href="/Superdashboard"
                            class="text-decoration-none text-info"
                          >
                            Dashboard
                          </a>
                        </li>
                        <li
                          class="breadcrumb-item bg-transparent"
                          style={{ fontFamily: "Calibri" }}
                        >
                          <a href="/lms" class="text-decoration-none text-info">
                            Leave Management
                          </a>
                        </li>
                        <li
                          class="breadcrumb-item text-muted text-capitalize bg-transparent"
                          aria-current="page"
                          style={{ fontSize: "bold" }}
                        >
                          Rejected Leaves
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div class="row my-2">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3">
                <div class="bg-white p-2  rounded-3 shadow-sm">
                  <div class="px-2 py-3">
                    <div class="container1">
                      <div class="row">
                        <div class=" col-xs-12 bg-white ">
                          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="icueSearchBox w-75 mx-3">
                              <div
                                class="input-group  "
                                style={{
                                  marginLeft: "-30px",
                                  marginBottom: "10px",
                                }}
                              >
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i class="bi bi-search"></i>
                                </span>
                                <input
                                  type="search"
                                  class="search form-control icueSearchBoxControl"
                                  placeholder="Search Users by Name, Email or Date"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  style={{ backgroundColor: "#F4F7FC" }}
                                />
                              </div>
                              {/* <a
                                  href="/AddNew"
                                  type="button"
                                  class="btn icueBgPrimary text-white pt-2  "
                                  style={{
                                    fontFamily: "Calibri",
                                    color: "white",
                                    fontSize: "bold",
                                    height: "35px",
                                    lineHeight: "10px",
                                    marginTop: "-55px",
                                    width: "95px",
                                    marginLeft: "370px",
                                    textAlign: "center",
                                  }}
                                >
                                  Searchfff
                                </a> */}
                            </div>
                          </div>
                        </div>

                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">
                          <div
                            class="tab-content py-1 px-3 px-sm-0"
                            id="myTabContent"
                          >
                            <div
                              class="tab-pane fade show active"
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              <div class="row">
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">
                                  {loading ? (
                                    <p>Loading...</p>
                                  ) : error ? (
                                    <p className="text-danger">{error}</p>
                                  ) : (
                                    <table
                                      id="payrollTable"
                                      className="table table-hover results"
                                      width="auto"
                                    >
                                      <thead>
                                        <tr
                                          className="text-uppercase"
                                          style={{
                                            lineHeight: "15px",
                                            fontFamily: "Calibri",
                                            fontStyle: "normal",
                                            fontSize: "12px",
                                            backgroundColor: "#F4F7FC",
                                          }}
                                        >
                                          <th class="py-3">EMP.CODE</th>
                                          <th class="py-3">EMP.NAME</th>
                                          <th class="py-3">LEAVE TYPE</th>
                                          <th class="py-3">FROM</th>
                                          <th class="py-3  hide-on-mobile">
                                            TO
                                          </th>
                                          <th class="py-3">NO. OF DAYS</th>
                                          <th class="py-3">REASON</th>
                                          <th class="py-3">LEAVE STATUS</th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        style={{
                                          lineHeight: "15px",
                                          fontFamily: "Calibri",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {rejectedLeaves.map((row, index) => (
                                          <tr
                                            key={row.id}
                                            style={{
                                              border: "0",
                                              backgroundColor:
                                                index % 2 === 0
                                                  ? "#FFFFFF"
                                                  : "#F4F7FC",
                                            }}
                                          >
                                            <td>{row.employee_code}</td>
                                            <td>{row.employee_name}</td>
                                            <td>{row.leave_type}</td>
                                            <td className="hide-on-mobile">
                                              {new Date(
                                                row.leave_from
                                              ).toLocaleDateString()}
                                            </td>
                                            <td>
                                              {new Date(
                                                row.leave_to
                                              ).toLocaleDateString()}
                                            </td>
                                            <td>{row.no_of_days}</td>
                                            <td>{row.leave_reason}</td>
                                            <td>
                                              <span className="badge rounded-pill bg-danger  px-2 py-2 w-75">
                                                Rejected
                                              </span>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
