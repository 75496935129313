import { Card, ListGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAdminRolesInfo } from "../store/actions/personalDetails";
import { ROLES } from "../utils/variables";
import down from "../assets/img/down-arrow.png";
import up from "../assets/img/up-arrow.png";
const scrollableStyle = {
  maxHeight: "100vh", // Limit the sidebar height to the viewport height
  overflowY: "auto", // Enable vertical scrolling
  overflowX: "hidden", // Prevent horizontal scrolling
};
const MenuItem = ({ path, name, toggleClass }) => {
  const location = useLocation();

  return (
    <Link
      to={path}
      className={`${location.pathname === path && "active"}`}
      onClick={toggleClass}
    >
      <ListGroup.Item>{name}</ListGroup.Item>
    </Link>
  );
};

const SideBar = ({ toggleClass, toggle, toogleSidebar }) => {
  const [toggleArrow, setToggleArrow] = useState(false);
  const [toggleArrowTwo, setToggleArrowTwo] = useState(false);
  const [toggleArrowHRMS, setToggleArrowHRMS] = useState(false);
  const [toggleArrowUserManagement, setToggleArrowUserManagement] =
    useState(false);
  const [toggleArrowLeaveManagement, setToggleArrowLeaveManagement] =
    useState(false);
  const [cattleRole, setCattleRoles] = useState([]);
  const [posRole, setPOSRoles] = useState([]);
  let role_name = JSON?.parse?.(localStorage?.getItem("superUser"))?.role;

  useEffect(() => {
    let role_id = ROLES[role_name];
    if (role_id) {
      getAdminRolesInfo(role_id).then((res) => {
        if (res.status) {
          let cattle = res.data.filter(
            (obj) => obj.module_name === "Cattle Insurance "
          );
          let pos = res.data.filter(
            (obj) => obj.module_name === "POS Management"
          );
          setCattleRoles(cattle);
          setPOSRoles(pos);
        }
      });
    }
  }, []);

  const handleArrowClick = () => {
    setToggleArrow(!toggleArrow);
    setToggleArrowTwo(false);
    setToggleArrowHRMS(false);
    setToggleArrowUserManagement(false);
  };

  const handleArrowClickTwo = () => {
    setToggleArrow(false);
    setToggleArrowTwo(!toggleArrowTwo);
    setToggleArrowHRMS(false);
    setToggleArrowUserManagement(false);
  };

  const handleArrowClickHRMS = () => {
    setToggleArrow(false);
    setToggleArrowTwo(false);
    setToggleArrowHRMS(!toggleArrowHRMS);
    setToggleArrowUserManagement(false);
  };

  const handleArrowClickUserManagement = () => {
    setToggleArrowUserManagement(!toggleArrowUserManagement);
  };
  const handleArrowClickLeaveManagement = () => {
    setToggleArrowLeaveManagement(!toggleArrowLeaveManagement);
  };

  return (
    <Card
      className={`sidebar rounded-0 ${toggleClass}`}
      style={scrollableStyle}
    >
      <div style={scrollableStyle}>
        {(ROLES[role_name] === 1 ||
          ROLES[role_name] === 2 ||
          ROLES[role_name] === 3) && (
          <Card.Body>
            <ListGroup variant="flush">
              {/* Existing List Items */}
              <MenuItem
                path="/pos/all"
                name="POS Details"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              {posRole[0]?.create_action === 1 && (
                <MenuItem
                  path="/pos/add"
                  name="Add POS"
                  toggleClass={toggle ? toogleSidebar : void 0}
                />
              )}
              <MenuItem
                path="/cattle-insurance/insurer-details"
                name="Share Details"
                toggleClass={toggle ? toogleSidebar : void 0}
              />
              <div
                className="py-3 px-2 d-flex justify-content-between"
                style={{ borderBottom: "1px solid lightgrey" }}
                onClick={handleArrowClick}
              >
                <div
                  style={{
                    fontFamily: "ErasDemiITC",
                    color: "#334d6e",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                >
                  Cattle Insurance
                </div>
                {!toggleArrow && <img src={down} style={{ height: "15px" }} />}
                {toggleArrow && <img src={up} style={{ height: "15px" }} />}
              </div>

              {toggleArrow && (
                <>
                  <MenuItem
                    path="/cattle-insurance/all"
                    name="Cattle Insurance Details"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  {cattleRole[0]?.create_action === 1 && (
                    <MenuItem
                      path="/cattle-insurance/add"
                      name="Add Cattle Insurance"
                      toggleClass={toggle ? toogleSidebar : void 0}
                    />
                  )}
                  <MenuItem
                    path="/cattle-insurance/endorsement"
                    name="Endorsement Management"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <MenuItem
                    path="/cattle-insurance/claim"
                    name="Claim Management"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <MenuItem
                    path="/cattle-insurance/doctor"
                    name="Doctor Management"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <MenuItem
                    path="/cattle-insurance/investigator"
                    name="Investigator Management"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <MenuItem
                    path="/cattle-insurance/surveyor"
                    name="Surveyor List"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                </>
              )}

              {/* Roles Dropdown */}
              {role_name === "Super Admin" && (
                <div
                  className="py-3 px-2 d-flex justify-content-between"
                  style={{ borderBottom: "1px solid lightgrey" }}
                  onClick={handleArrowClickTwo}
                >
                  <div
                    style={{
                      fontFamily: "ErasDemiITC",
                      color: "#334d6e",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                  >
                    Role Management
                  </div>
                  {!toggleArrowTwo && (
                    <img src={down} style={{ height: "15px" }} />
                  )}
                  {toggleArrowTwo && (
                    <img src={up} style={{ height: "15px" }} />
                  )}
                </div>
              )}
              {toggleArrowTwo && (
                <>
                  <MenuItem
                    path="/cattle-insurance/role"
                    name="Role"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <MenuItem
                    path="/cattle-insurance/role-permission"
                    name="Role Permission"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                </>
              )}

              {/* HRMS Section */}
              <div
                className="py-3 px-2 d-flex justify-content-between"
                style={{ borderBottom: "1px solid lightgrey" }}
                onClick={handleArrowClickHRMS}
              >
                <div
                  style={{
                    fontFamily: "ErasDemiITC",
                    color: "#334d6e",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                >
                  HRMS
                </div>
                {!toggleArrowHRMS && (
                  <img src={down} style={{ height: "15px" }} />
                )}
                {toggleArrowHRMS && <img src={up} style={{ height: "15px" }} />}
              </div>

              {toggleArrowHRMS && (
                <>
                  <MenuItem
                    path="/hrms/dashboard"
                    name="Dashboard"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <MenuItem
                    path="/hrms/onboarding"
                    name="Onboarding"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  {/* User Management Dropdown */}
                  <div
                    className="py-3 px-2 d-flex justify-content-between"
                    onClick={handleArrowClickUserManagement}
                  >
                    <div
                      style={{
                        fontFamily: "ErasDemiITC",
                        color: "#334d6e",
                        fontSize: "15px",
                        cursor: "pointer",
                      }}
                    >
                      User Management
                    </div>
                    {!toggleArrowUserManagement && (
                      <img src={down} style={{ height: "15px" }} />
                    )}
                    {toggleArrowUserManagement && (
                      <img src={up} style={{ height: "15px" }} />
                    )}
                  </div>
                  {toggleArrowUserManagement && (
                    <>
                      {/* <MenuItem
                        path="/hrms/user-management/add-member"
                        name="Add Member"
                        toggleClass={toggle ? toogleSidebar : void 0}
                      /> */}

                      <MenuItem
                        path="/hrms/user-management/list-members"
                        name="List of Members"
                        toggleClass={toggle ? toogleSidebar : void 0}
                      />
                    </>
                  )}
                  <MenuItem
                    path="/hrms/department"
                    name="Department"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <MenuItem
                    path="/hrms/roles"
                    name="Roles"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <div
                    className="py-3 px-2 d-flex justify-content-between"
                    onClick={handleArrowClickLeaveManagement}
                  >
                    <div
                      style={{
                        fontFamily: "ErasDemiITC",
                        color: "#334d6e",
                        fontSize: "15px",
                        cursor: "pointer",
                      }}
                    >
                      Leave Management
                    </div>
                    {!toggleArrowLeaveManagement && (
                      <img src={down} style={{ height: "15px" }} />
                    )}
                    {toggleArrowLeaveManagement && (
                      <img src={up} style={{ height: "15px" }} />
                    )}
                  </div>
                  {toggleArrowLeaveManagement && (
                    <>
                      <MenuItem
                        path="/hrms/Leave-management/Requested-Leaves"
                        name="Requested Leaves "
                        toggleClass={toggle ? toogleSidebar : void 0}
                      />
                      <MenuItem
                        path="/hrms/Leave-management/Approved-Leaves"
                        name="Approved Leaves"
                        toggleClass={toggle ? toogleSidebar : void 0}
                      />
                      <MenuItem
                        path="/hrms/Leave-management/Rejected-Leaves"
                        name="Rejected Leaves"
                        toggleClass={toggle ? toogleSidebar : void 0}
                      />
                    </>
                  )}

                  <MenuItem
                    path="/hrms/attendance"
                    name="Attendance"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                  <MenuItem
                    path="/hrms/holidays"
                    name="Holidays"
                    toggleClass={toggle ? toogleSidebar : void 0}
                  />
                </>
              )}
            </ListGroup>
          </Card.Body>
        )}
      </div>
    </Card>
  );
};

export default SideBar;
